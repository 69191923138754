/* SCSS RGB */
$rich-black: rgba(1, 2, 3, 1);
$russian-violet: rgba(66, 37, 90, 1);
$pink-sherbet: rgba(254, 147, 171, 1);
$cyber-grape: rgba(97, 75, 150, 1);
$melon: rgba(255, 181, 167, 1);
$cg-blue: #0a7ba3ff;
$gradient-left: linear-gradient(270deg, #42255Aff, #FE93ABff, #614B96ff, #FFB5A7ff, #0A7BA3ff);

html {
  height: -webkit-fill-available;
}

html, body {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
}

body {
  align-items: center;
  display: flex;
  flex-flow: column;
  font-size: 16px;
  height: 100vh;
  max-height: -webkit-fill-available;
  margin: 0 auto;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Quicksand', sans-serif;
  margin: 0;
  padding: 0;
}

p:first-child {
  margin-top: 0;
}

a {
  color: $russian-violet;
  text-decoration: none;

  &:hover, &:focus {
    color: $cyber-grape;
  }
}

.navigation, .footer {
  background-size: 200%;
  background-image: $gradient-left;
  box-sizing: border-box;
  padding: 1rem;
  text-align: center;
  width: 100%;

  a {
    text-shadow: 1px 1px 2px $pink-sherbet;
  }
}

.container {
  margin: 0 auto;
  max-width: 40rem;
}

.with-shadow {
  box-shadow: 0px 0px 24px -8px $rich-black;
  height: -webkit-fill-available;
  padding: 1rem;
}

.navigation {
  align-items: center;
  display: flex;
  flex-flow: column;

  .logo {
    align-items: center;
    text-shadow: none;
    color: $russian-violet;
    display: flex;
    font-family: 'Montserrat-Alt1';
    font-size: 2rem;
    font-weight: 400;

    img {
      height: 2.5rem;
      margin-bottom: 3px;
    }
  }
}

.footer {
  display: flex;
  flex-flow: column;
  box-shadow: 0px 0px 24px -8px $rich-black;
  .copyright {
    font-size: 0.5rem;
  }
}

.card-list {
  background-color: rgba($melon, 0.25);
  color: $rich-black;
  font-family: 'Noto Serif', serif;
  height: -webkit-fill-available;
  overflow-y: auto;
  width: 100%;

  .card {
    font-family: 'Noto Serif', serif;

    a {
      display: block;
      padding: 1rem;
      text-decoration: none;

      h2 {
        color: $cyber-grape;
      }

      p {
        color: $rich-black;
      }
    }

    &:nth-child(odd) {
      background-color: rgba($melon, 0.25);
    }

    &:nth-child(even) {
      background-color: rgba($pink-sherbet, 0.25);
    }
  }
}

.post {
  display: flex;
  flex-flow: column;
  height: -webkit-fill-available;
  overflow-y: hidden;
  width: 100%;
  
  .title-and-byline {
    background-color: rgba($melon, 0.5);
    box-shadow: 0px 0px 24px -8px $rich-black;
    color: $cyber-grape;
    padding: 1rem;
    p {
      font-family: 'Quicksand', sans-serif;
      margin: 0;
      padding: 0;
    }
  }

  .content {
    background-color: rgba($melon, 0.25);
    color: $rich-black;
    flex-grow: 1;
    font-family: 'Noto Serif', serif;
    overflow-y: auto;
    scrollbar-gutter: stable both-edges;
  }
}


